import { RedirectServiceType } from '@Client/runner.redirect/runner.redirect.service';
import IProcessMapNestDetails from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNestDetails';
import { HttpStatusCodes } from '@Shared.Angular/@types/core/contracts/constants/httpStatusCodes';
import angular from 'angular';

export class RunnerPublicMapApiService {
  constructor(
    private $http: angular.IHttpService,
    private APP_CONFIG: AppConfig,
    private redirectService: RedirectServiceType
  ) {}

  getPublicProcessMap(flowModelId: string) {
    return this.$http
      .get<IProcessMapNestDetails>(
        `${this.APP_CONFIG.apiBaseUrl}map/${flowModelId}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err.status === HttpStatusCodes.Unauthorized) {
          this.redirectService.setPendingRequestAsUrl(`/map/${flowModelId}`);
          window.location.href = `${window.location.origin}/login?redirectUrl&forceRelogin`;
        }
      });
  }
}

angular
  .module('flowingly.runner.services')
  .factory('runnerPublicMapApiService', [
    '$http',
    'APP_CONFIG',
    'redirectService',
    (
      $http: angular.IHttpService,
      APP_CONFIG: AppConfig,
      redirectService: RedirectServiceType
    ) => new RunnerPublicMapApiService($http, APP_CONFIG, redirectService)
  ]);

export type RunnerPublicMapApiServiceType = InstanceType<
  typeof RunnerPublicMapApiService
>;
